<template>
  <section>
    <!-- <Loader :isLoading="isLoading" /> -->
    <!-- <div v-if="!isLoading"> -->
      <div class="header-home shadow-sm">
        <b-row class="mx-0">
          <b-col class="sm-2 px-0"> </b-col>
          <b-col class="sm-8 px-0">
            <img
              alt="Vue logo"
              src="@/assets/logo.png"
              style="width: 100%; margin:1rem !important;"
            />
          </b-col>
          <b-col class="sm-2 px-0">
            <div class="mt-2 mr-3 d-none">
              <b-media right-align vertical-align="center" class="ml-0">
                <template #aside>
                  <img
                    alt="Vue logo"
                    src="@/assets/icon-token.png"
                    class="mt-1"
                    style="width: 2.5rem; height: 2.5rem"
                  />
                </template>
                <h4 class="mt-3 mb-1 font-weight-bold text-light text-right">
                  {{ coin !== null ? coin : 0 }}
                </h4>
              </b-media>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="home px-3">
        <Gallery />
      </div>
    <!-- </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import Gallery from "@/components/Gallery.vue"
import router from '@/router'
// import Loader from '@/components/Loader.vue'
export default {
  name: "Home",
  components: {
    Gallery,
    // Loader,
  },
  data() {
    return {
      swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      // isLoading: true,
      },

      dataLeaderboard: [],
      dataProfile: null,
    };
  },
  mounted() {
  
  },
  computed: {
    ...mapState([
      'coin',
      'reward',
      'point',
      'leaderboardList',
      'bannerList',
      'bannerFitur',
    ])
  },
  methods: {
    handleSubmit() {},
    goTo(page) {
      router.push({ name: page });
    },
    ...mapActions([
      'getLeaderboard',
      // 'getGameReward',
      'getDetail',
      'getBanner',
      'getProfile'
      // 'getGames'
    ]),
    homeLeaderboard(){
      const lbList = this.leaderboardList;
      const newList = lbList.slice(0,3)
      // console.log(newList)
      return newList
      // this.dataLeaderboard = newList
    }
  }
};
</script>

<style scoped>
body {
  background-color: white;
}
.header-home {
  height: 112px;
  background: #f7941d;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}
.home {
  padding-top: 9rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 0.5rem !important;
}
</style>
